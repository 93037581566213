<style lang="scss">
  #home {
    $grey-lighter: #f6f6f6;
    line-height: 1.61;
    .f-14 {
      font-size: 16px !important;
    }
    .f-16 {
      font-size: 16px !important;
    }
    .f-18 {
      font-size: 18px !important;
    }
    .f-20 {
      font-size: 20px !important;
    }
    .bold {
      font-weight: bold !important;
    }
    #hero {
      background: repeating-linear-gradient(
        0deg,
        #fdd835 0% 30%,
        transparent 30% 100%
      );
    }
    .underline {
      background: repeating-linear-gradient(
        0deg,
        #e8c52e 0px 1px,
        transparent 2px 3px,
        #fdd835 3px 10px,
        transparent 10px 100%
      );
    }
    .underline-md {
      background: repeating-linear-gradient(
        0deg,
        #e8c52e 0px 1px,
        transparent 2px 3px,
        #fdd835 3px 15px,
        transparent 16px 100%
      );
    }
    .feature {
      background-color: $grey-lighter;
    }
    .section {
      &:nth-child(even) {
        background-color: $grey-lighter;
      }
      .container {
        max-width: 1100px;
      }
      header {
        text-align: center;
        .title {
          font-size: 24px;
          font-weight: bold;
        }
        .subtitle {
          font-size: 12px;
          font-weight: bold;
        }
        .line {
          display: inline-block;
          width: 40px;
          background-color: black;
          height: 4px;
        }
      }
    }
  }
</style>

<template>
  <div id="home">
    <header id="hero" class="text-center mt-sm-8">
      <v-container style="max-width: 1100px">
        <v-row>
          <v-col class="d-flex">
            <v-img
              src="@/assets/logo.png"
              contain
              width="40%"
              max-width="280"
              alt="RCBooking"
            ></v-img>
            <!-- <img src="@/assets/logo.png" width="40%" height="auto" /> -->
            <v-spacer></v-spacer>
            <v-btn
              depressed
              rounded
              light
              color="primary black--text font-weight-bold"
              class="px-8"
              :href="contactUrl"
              target="_blank"
            >
              お問い合わせ
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet class="black primary--text d-inline-block px-4 py-1 mt-8">
              <h2>初期費用・月額利用料無料</h2>
            </v-sheet>
            <div class="text-h6 text-sm-h4 mt-4">
              シンプルデザイン＆簡単セットアップ<br />
              機能充実の宿泊予約システム
            </div>
            <div class="d-flex justify-center mt-4">
              <v-img
                src="@/assets/202210/hero.png"
                contain
                max-width="585"
                width="90vw"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </header>
    <section class="section py-6">
      <v-container>
        <v-row class="text-center">
          <v-col>
            <h2 class="text-body-1 text-sm-h5 font-weight-bold">
              <div class="d-inline-block">
                <div class="underline">
                  国内外の直販プロモーションを簡単に！
                </div>
              </div>
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-sm-center">
            <span class="d-sm-block mt-sm-2"
              >成果報酬型で公式宿泊プランを</span
            >
            <span class="d-sm-block mt-sm-2"
              >Googleビジネスプロフィールのホテル広告に自動連携し</span
            >
            <span class="d-sm-block mt-sm-2"
              >国内外の直販プロモーションを簡単に！</span
            ><br class="d-sm-none" />
            <span class="d-sm-block mt-sm-2"
              >Googleプロモーションに特化したセカンド予約エンジンとして他社予約エンジンと並走可能。</span
            >
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="section py-6">
      <v-container>
        <v-row>
          <v-col>
            <header>
              <h1 class="title">機能について</h1>
              <div class="subtitle">FEATURE</div>
              <div class="line"></div>
            </header>
            <p class="mt-6">
              充実した機能で自社公式予約サイトとしても、Google集客に特化したセカンド予約サイトとしてもご利用いただけます。
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col v-for="(title, n) in features" :key="n" cols="12" sm="6">
            <v-sheet outlined class="feature d-flex font-weight-bold">
              <div class="py-2 ml-2" style="position: relative">
                <div
                  class="primary"
                  style="position: absolute; top: 0; width: 100%; height: 4px"
                ></div>
                <div>
                  {{ String(n + 1).padStart(2, '0') }}
                </div>
              </div>
              <div class="pa-2">{{ title }}</div>
            </v-sheet>
          </v-col>
        </v-row>
        <footer class="text-caption mt-4">
          <div>※1 サイトコントローラー非使用時</div>
          <div>※2 別途お申し込みが必要です</div>
        </footer>
      </v-container>
    </section>
    <section class="section py-6">
      <v-container>
        <v-row>
          <v-col>
            <header>
              <h1 class="title">料金について</h1>
              <div class="subtitle">PRICE</div>
              <div class="line"></div>
            </header>
            <div class="text-center">
              <h2
                class="d-inline-block pa-1 mt-6"
                style="border: 1px solid #aaa"
              >
                <div
                  class="d-inline-block px-4 py-1"
                  style="border: 1px solid #aaa"
                >
                  初期費用・月額利用料無料
                </div>
              </h2>
              <p class="f-18 bold mt-6">
                RCBookingは成果報酬です。
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="pb-8 px-md-8">
              <v-container>
                <header class="mt-6">
                  <h1 class="title">施設様にご負担いただく料金について</h1>
                  <div class="line"></div>
                </header>
                <section class="mt-10">
                  <h1 class="f-20 bold mb-4">
                    Googleビジネスプロフィールに掲載されるGoogleホテル広告実費
                  </h1>
                  <p>
                    Googleホテル広告は成果報酬です。キャンセルされた場合、期日までにキャンセル処理を行っていだければGoogleへの成果報酬はかかりません。
                  </p>
                  <p>
                    Googleホテル広告はGoogleホテル広告経由で予約が成立した時の成果報酬額（Google広告料）を自由に設定するだけで、面倒な広告運用業務はRCBookingが全て代行いたします。
                  </p>
                  <p>
                    Google広告料は、CPC（クリック単価）での入札方法を採用し、1円～設定可能です。上位4位に表示された場合のみが成果報酬支払対象となります。
                  </p>
                  <p>
                    上位5位以下で予約された場合はGoogle広告料はかかりません。
                  </p>
                </section>
                <section class="mt-6">
                  <h1 class="f-20 bold mb-4">サービス手数料</h1>
                  <p>
                    全ての予約総額の２％相当額
                  </p>
                </section>
                <section class="mt-6">
                  <h1 class="f-20 bold mb-4">アカウント維持手数料</h1>
                  <p>
                    ご利用開始の3ヶ月目より毎月1日に当月及び翌月の未来2ヶ月間に在庫及び料金が設定されたプランが10日未満の場合に、アカウント維持手数料3000円をご請求させていただきます。在庫・料金が設定されたプランが10日以上存在する場合は、費用はかかりません。
                  </p>
                  <v-sheet outlined class="d-inline-block">
                    <v-container>
                      例：利用開始日10/1ならば、アカウント維持手数料判定日12/1〜となります。
                    </v-container>
                  </v-sheet>
                </section>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="section py-6">
      <v-container>
        <v-row>
          <v-col>
            <header>
              <h1 class="title">ご契約までの流れ</h1>
              <div class="subtitle">FLOW</div>
              <div class="line"></div>
            </header>
            <section class="mt-8">
              <div class="d-flex">
                <header
                  class="d-inlinee-block text-h5 font-weight-bold underline"
                >
                  ご契約はオンラインで完結！
                </header>
              </div>
              <v-img
                src="@/assets/202210/flow_01_sp.svg"
                class="d-sm-none mt-8"
              ></v-img>
              <v-img
                src="@/assets/202210/flow_01.svg"
                class="d-none d-sm-block mt-8"
              ></v-img>
            </section>
            <section class="mt-8">
              <div class="d-flex">
                <header
                  class="d-inlinee-block text-h5 font-weight-bold underline"
                >
                  ご契約完了からご利用開始までの流れ
                </header>
              </div>
              <v-img
                src="@/assets/202210/flow_02_sp.svg"
                class="d-sm-none mt-8"
              ></v-img>
              <v-img
                src="@/assets/202210/flow_02.svg"
                class="d-none d-sm-block mt-8"
              ></v-img>
            </section>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="section py-6">
      <v-container>
        <v-row>
          <v-col>
            <header>
              <h1 class="title">その他のサービス</h1>
              <div class="subtitle">OTHER SERVICES</div>
              <div class="line"></div>
            </header>
            <section class="mt-8">
              <header class="text-center mt-8">
                <div class="d-flex justify-center">
                  <div
                    class="d-inlinee-block text-h5 font-weight-bold underline"
                  >
                    初期設定おまかせパッケージ
                  </div>
                </div>
                <div class="text-body-1 font-weight-bold mt-1">
                  （オンラインサポート）
                </div>
              </header>
              <p class="mt-8 text-sm-center">
                <span class="d-sm-block"
                  >施設情報、写真、掲載したいプラン等の情報を施設様よりRCBookingにご提供いただき、</span
                >
                <span class="d-sm-block"
                  >ご提供頂いた情報を元に販売開始できる状態にRCBookingスタッフが設定いたします。</span
                >
              </p>
              <div class="text-sm-center">
                サイトコントローラーご利用時は、サイトコントローラー側の設定も代行可能です。
              </div>
            </section>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="section py-6">
      <v-container>
        <v-row align="center" justify="center">
          <v-btn
            depressed
            rounded
            light
            color="primary black--text font-weight-bold"
            class="px-8"
            :href="contactUrl"
            target="_blank"
          >
            お問い合わせ
          </v-btn>
        </v-row>
      </v-container>
    </section>
    <PsFooter />
  </div>
</template>

<script>
  import PsFooter from '../components/PsFooter'
  export default {
    components: {
      PsFooter,
    },
    created() {
      this.$vuetify.theme.themes.light.primary = '#FDD835'
      this.$vuetify.theme.themes.dark.primary = '#FDD835'
      this.$vuetify.theme.default = 'light'
      this.$vuetify.theme.dark = false
      // this.$vuetify.theme = {
      //   dark: false,
      //   themes: {
      //     light: {
      //       primary: '#1976D2',
      //       secondary: '#424242',
      //       accent: '#82B1FF',
      //       error: '#FF5252',
      //       info: '#2196F3',
      //       success: '#4CAF50',
      //       warning: '#FB8C00',
      //     },
      //     dark: {
      //       primary: '#2196F3',
      //       secondary: '#424242',
      //       accent: '#FF4081',
      //       error: '#FF5252',
      //       info: '#2196F3',
      //       success: '#4CAF50',
      //       warning: '#FB8C00',
      //     },
      //   },
      // }
    },
    computed: {
      contactUrl() {
        return 'https://contact.psinc.jp'
      },
      rcUrl() {
        return 'https://repchecker.jp'
      },
      afUrl() {
        return 'https://aiface.jp'
      },
      psUrl() {
        return 'https://paradigmshift.io'
      },
      features() {
        return [
          'オリジナルヘッダー機能',
          '多言語対応 (日・英・簡中・繁中・韓)',
          'プランごとキャンセルポリシー設定機能',
          '小人ランク設定機能',
          'プラン連泊割引または自動割引機能',
          'プラングループ特別割引機能',
          'シークレットプラン機能', //'プロモーションコード機能',
          'イメージギャラリー機能',
          'オプション販売機能',
          '予約質問設定機能',
          '予約リマインドメール自動送信機能',
          'ウェルカム、サンキューメール自動送信',
          '独自ドメインメール設定機能',
          'キャンセル料支払い口座自動案内機能',
          'サイトコントローラー連携',
          '在庫・料金管理※1',
          'Googleホテル広告レポート',
          '事前クレジットカード決済機能※2',
        ]
      },
    },
  }
</script>
